body {
  background-color: #dff4f9;
}

.nav-bar {
  width: 100%;
  height: 60px;
  background-color: #1d3557;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nav-container {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.nav-container a {
  color: white;
}

.container {
  width: 100%;
  min-height: 100vh;
  color: rgb(0, 0, 0);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.content {
  width: 90%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.page_nav {
  display: flex;
  align-items: center;
}

.page_nav a {
  text-decoration: none;
}

.page_nav div {
  margin-right: 20px;
}

.logo {
  font-size: 30px;
  font-weight: bold;
}

.logo a {
  text-decoration: none;
}

.navigation a {
  font-size: 18;
  margin-right: 10px;
  text-decoration: none;
}

.loader {
  width: 60px;
  height: 60px;
  align-self: center;
  background-color: #1d3557;
  border-radius: 10px;
  padding: 10px;
}

.loader img {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.mb {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.mb h3 {
  margin: 5px;
}

.project {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.project a {
  text-decoration: none;
  color: rgb(0, 66, 165);
}

.project a:visited {
  text-decoration: none;
  color: rgb(0, 66, 165);
}

.prj_title {
  text-align: center;
}

h1 {
  color: #1d3557;
  text-align: center;
  margin: 10px;
}

.project h2 {
  margin: 5px;
}

.button_cnt {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
}

.prj_date {
  text-align: right;
  color: rgb(92, 90, 90);
  font-size: 15px;
  display: flex;
  justify-content: space-between;
}

.prj_date div {
  display: flex;
  align-items: center;
}

.prj_date div>* {
  margin-right: 5px;
}

.prj_date button {
  /* background-color: white;
  border: 1px solid lightgray;
  border-radius: 4px; */
  padding: 4px;
  margin: 0px
}

.form label {
  display: block !important;
  font-weight: bold !important;
}

.section {
  background-color: rgb(255, 255, 255);
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  width: 100%;
}

.taskcnt {
  display: flex;
  align-items: center;
}

.tasknum {
  font-size: 15px;
  font-weight: bold;
  /* margin-right: 10px; */
  text-align: center;
  align-self: stretch;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.taskedit {
  width: 100%;
  font-size: 15px
}

.taskedit p {
  padding: 0px !important;
  margin: 5px !important;
}

.green {
  border: 1px solid green;
  padding: 0px !important;
}

.red {
  border: 1px solid red;
  padding: 0px !important;
}

.neutral {
  border: 1px solid #e2e2e2;
  /* lightgray #dfeefb*/
  padding: 0px !important;
}

.neutral .tasknum,
.taskedit {
  padding: 5px;
}

.red .tasknum,
.taskedit {
  padding: 5px;
}

.green .tasknum,
.taskedit {
  padding: 5px;
}

.neutral .tasknum {
  background-color: #e2e2e2;
  /*  lightgray; #dfeefb*/
  border-radius: 3px 0px 0px 3px;
}

.red .tasknum {
  background-color: rgb(230, 198, 198);
  border-radius: 3px 0px 0px 3px;
}

.green .tasknum {
  background-color: rgb(169, 236, 174);
  border-radius: 3px 0px 0px 3px;
}

.red .tasknum::before {
  content: "❌";
}

.green .tasknum::before {
  content: "✅";
}

.neutral .tasknum::before {
  content: "🔘";
}

.itemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete::before {
  content: "🗑️";
}

.hide {
  display: none
}

.accTitle {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-content: space-between;
  align-items: center;
}

.spaceUp {
  margin-top: 10px;
}

.calendar {
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
  padding: 0px;
}

.calendar_day {
  width: 20%;
  min-width: 289px;
  /*   border: 1px solid lightgray; */
  margin: 4px;
  padding: 0px;
}

.calendar_date {
  background-color: #264570;
  border-radius: 4px;
  padding: 5px;
  color: white;
  margin-bottom: 10px;
}

.calendar_cnt {
  min-height: 60px;
}

.css-o4b71y-MuiAccordionSummary-content {
  margin: 0px !important;
}

.user_img {
  max-width: 30px;
  max-height: 30px;
  border-radius: 50%;
  margin: 0px;
}

.user {
  font-weight: bold;
}

/* .form {
  min-width: 300px;
} */

.form input {
  height: 35px;
  width: 100%;
}

.form select {
  height: 35px;
  width: 100%;
}

.form>* {
  margin-bottom: 5px;
  margin-top: 5px;
}

.profile_details {
  display: flex;
}

.profile_details img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 50%;
}

.profile_details ul {
  list-style: none
}

.profile_details li {
  font-size: 15px;
}

.tags_container {
  width: 100px;
  overflow-y: scroll;
}

.cal_day {
  width: 100%;
  border: 1px solid blue;
}

.hide {
  display: none;
}

.content img {
  max-width: 100%;
}